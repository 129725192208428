
import api from '@/api/co.api.js'

const UserLevelDescription = {
}

const UserTraggers = {
  level: {
    key: 'user_level',
    enabled: true,
    execute (ev) {
      const levels = UserLevelDescription
      const len = ev.data.length
      for (let i = 0; i < len; i++) {
        const item = ev.data[i]
        const key = `level${item.value}`
        levels[key] = {
          text: item.text,
          value: item.value,
          style: api.comm.StateStyles.BLACK,
          visible: true,
          disabled: !item.enabled
        }
      }
    }
  }
}

const UserTypes = {
  EMPTY: 'empty',
  // 普通用户
  USER: 'user',
  // 领队
  LEADER: 'leader',
  // 专家
  EXPERT: 'expert',
  // 配送员
  COURIER: 'courier',
  // 驿站员
  HOP: 'hop',
  // 经理
  MANAGER: 'manager'
}

const UserTypeDescs = {
  [UserTypes.EMPTY]: {
    text: '默认',
    value: UserTypes.EMPTY,
    style: api.comm.StateStyles.GRAY,
    visible: true,
    disabled: false,
    type: 'info',
    effect: 'plain'
  },
  [UserTypes.USER]: {
    text: '会员',
    value: UserTypes.USER,
    style: api.comm.StateStyles.BLACK,
    visible: true,
    disabled: false,
    type: 'info',
    effect: 'light'
  },
  [UserTypes.LEADER]: {
    text: '领队',
    value: UserTypes.LEADER,
    style: api.comm.StateStyles.CYAN,
    visible: true,
    disabled: false,
    type: 'primary',
    effect: 'light'
  },
  [UserTypes.EXPERT]: {
    text: '专家',
    value: UserTypes.EXPERT,
    style: api.comm.StateStyles.BLUE,
    visible: true,
    disabled: false,
    type: 'warning',
    effect: 'light'
  },
  [UserTypes.COURIER]: {
    text: '配送员',
    value: UserTypes.COURIER,
    style: api.comm.StateStyles.ORANGE,
    visible: true,
    disabled: false,
    type: 'warning',
    effect: 'light'
  },
  [UserTypes.HOP]: {
    text: '驿站员',
    value: UserTypes.HOP,
    style: api.comm.StateStyles.BLUE,
    visible: true,
    disabled: false,
    type: 'primary',
    effect: 'light'
  },
  [UserTypes.MANAGER]: {
    text: '管理员',
    value: UserTypes.MANAGER,
    style: api.comm.StateStyles.RED,
    visible: true,
    disabled: false,
    type: 'danger',
    effect: 'light'
  }
}

const GenderTypes = {
  DEFAULT: '0',
  MALE: '1',
  FEMALE: '2'
}

const GenderTypeDescs = {
  [GenderTypes.DEFAULT]: {
    text: '默认',
    value: GenderTypes.DEFAULT,
    style: api.comm.StateStyles.BLACK,
    visible: true,
    disabled: false,
    type: 'info',
    effect: 'plain'
  },
  [GenderTypes.MALE]: {
    text: '男',
    value: GenderTypes.MALE,
    style: api.comm.StateStyles.BLUE,
    visible: true,
    disabled: false,
    type: 'primary',
    effect: 'plain'
  },
  [GenderTypes.FEMALE]: {
    text: '女',
    value: GenderTypes.FEMALE,
    style: api.comm.StateStyles.RED,
    visible: true,
    disabled: false,
    type: 'danger',
    effect: 'plain'
  }
}

const UserFields = {
  username: {
    text: '登录名称',
    key: 'username',
    hint: '请填写登录名称'
  },
  nickname: {
    text: '用户别名',
    key: 'nickname',
    hint: '请填写用户别名'
  },
  signature: {
    text: '个性签名',
    key: 'signature',
    hint: '请填写个性签名'
  },
  password: {
    text: '登录密码',
    key: 'password',
    hint: '请填写登录密码'
  },
  mobile: {
    text: '手机号码',
    key: 'mobile',
    hint: '请填写手机号码'
  },
  email: {
    text: '联系邮箱',
    key: 'email',
    hint: '请填写联系邮箱'
  },
  userLevel: {
    text: '用户等级',
    key: 'userLevel',
    hint: '请选择用户等级'
  },
  type: {
    text: '用户类型',
    key: 'type',
    hint: '请选择用户类型'
  },
  gender: {
    text: '性别',
    key: 'gender',
    hint: '请选择性别'
  },
  coupon: {
    text: '享有折扣',
    key: 'coupon',
    hint: '请输入享有折扣(0 ~ 1)之间'
  },
  avatar: {
    text: '头像',
    key: 'avatar',
    hint: '请选择头像'
  },
  userTags: {
    text: '用户标签',
    key: 'userTags',
    hint: '请选择用户标签'
  },
  companyName: {
    text: '所属公司',
    key: 'companyId',
    hint: '请选择所属公司'
  },
  department: {
    text: '所在部门',
    key: 'department',
    hint: '请输入所在部门'
  },
  createTime: {
    text: '创建时间',
    key: 'createTime',
    hint: ''
  }
}

const coUserComm = {
  Types: UserTypes,
  Genders: GenderTypes,
  TypeDescs: UserTypeDescs,
  GenderDescs: GenderTypeDescs,
  Fields: UserFields,
  Traggers: UserTraggers,
  getTypeDescription (type) {
    let desc = null
    if (type) {
      desc = UserTypeDescs[type]
    }

    if (!desc) {
      desc = api.comm.createDescription(type)
    }

    return desc
  },
  getTypeDescriptions (addAll = false, excludes = []) {
    const items = []

    const includes = []
    for (const key in UserTypeDescs) {
      if (!excludes.includes(key)) {
        includes.push(key)
        items.push({
          ...UserTypeDescs[key]
        })
      }
    }

    if (addAll) {
      items.unshift({
        text: '所有类型',
        value: includes.join('|')
      })
    }

    return items
  },
  getGenderDescription (type) {
    let desc = GenderTypeDescs[type]
    if (!desc) {
      desc = api.comm.createDescription(type)
    }
    return desc
  },
  getGenderDescriptions () {
    const _items = []
    for (const key in GenderTypeDescs) {
      const _item = GenderTypeDescs[key]
      _items.push(_item)
    }
    return _items
  },
  getLevelDescription (levelType) {
    const key = `level${levelType}`
    let desc = UserLevelDescription[key]
    if (!desc) {
      desc = api.comm.createDescription(levelType)
    }
    return desc
  },
  getLevelDescriptions () {
    const levels = []
    for (const i in UserLevelDescription) {
      const item = UserLevelDescription[i]
      levels.push(item)
    }
    return levels
  },
}

export default coUserComm
