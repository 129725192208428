
import userComm from '@/api/user/co.user.comm'
import userList from '@/api/user/co.user.list'
import userSignin from '@/api/user/co.user.signin'
import userSite from '@/api/user/co.user.site'

const coUser = {
  comm: userComm,
  list: userList,
  signin: userSignin,
  site: userSite
}

export default coUser
