
const States = {
  CREATED: 'created',
  SUCCESS: 'success',
  FAILED: 'failed'
}

const Types = {
  practice: 'practice',
  exam: 'exam',
  vote: 'vote',
  questionnaire: 'questionnaire',
  share: 'share',
  exchange: 'exchange'
}

const StateDescs = {
  [States.CREATED]: {
    text: '待定',
    value: States.CREATED,
    effect: 'dark',
    type: 'black',
  },
  [States.SUCCESS]: {
    text: '成功',
    value: States.SUCCESS,
    effect: 'dark',
    type: 'success',
  },
  [States.FAILED]: {
    text: '失败',
    value: States.FAILED,
    effect: 'dark',
    type: 'error',
  },
}

const TypeDescs = {
  [Types.practice]: {
    text: '练习',
    value: Types.practice,
    effect: 'dark',
    type: 'orange',
  },
  [Types.exam]: {
    text: '测试',
    value: Types.exam,
    effect: 'dark',
    type: 'error',
  },
  [Types.vote]: {
    text: '投票',
    value: Types.vote,
    effect: 'dark',
    type: 'cyan',
  },
  [Types.questionnaire]: {
    text: '问卷',
    value: Types.questionnaire,
    effect: 'dark',
    type: 'teal',
  },
  [Types.exchange]: {
    text: '兑换',
    value: Types.exchange,
    effect: 'dark',
    type: 'black',
  },
}

const balanceComm = {
  States,
  StateDescs,
  Types,
  TypeDescs,
  getStateDesc (state) {
    return StateDescs[state] || {
      text: '未定义',
      value: state,
      effect: 'dark',
      type: 'gray',
    }
  },
  getTypeDesc (type) {
    return TypeDescs[type] || {
      text: '未定义',
      value: type,
      effect: 'dark',
      type: 'gray',
    }
  }
}

export default balanceComm
