
import api from '@/api/co.api'
import app from '@/api/co.app'
import user from '@/api/co.user'
import survey from '@/api/co.survey'
import balance from '@/api/co.balance'

const QuesTypes = survey.comm.QuestionTypes

export default {
  data () {
    return {
      quesTypes: QuesTypes,
      userInfo: {},
      quesMeta: {},
      quesApp: {
        id: '',
        category: '',
        type: '',
        limit: '10',
        title: '',
        current: 0,
        total: 0,
        imgCode: ''
      },
      captcha: {
        key: '',
        receiver: '',
        scope: 'report',
        type: '0',
        value: ''
      },
      quesCountdown: {
        houred: false,
        backColor: 'gray800',
        borderColor: 'gray800',
        foreColor: 'white',
        splitorColor: 'gray500',
        duration: 10,
        finished: false
      },
      quesLabels: {},
      quesInfo: {},
      currQuestion: {},
      submitEnableTypes: [
        survey.comm.Types.QUESTIONNAIRE,
        survey.comm.Types.VOTE
      ],
      wdCode: {
        name: 'imgCode',
        label: '验证码',
        holder: '输入4位验证码',
        value: '',
        maxlength: '4',
        rules: [
          val => !!val || '必须填写验证码',
          val => (val && val.length === 4) || '填写4位验证码',
        ],
      },
      btnPrev: {
        text: '上一题',
        disabled: true,
        visible: true
      },
      btnNext: {
        text: '下一题',
        disabled: true,
        visible: true
      },
      btnSubmit: {
        text: '提交',
        disabled: true,
        visible: false,
        loading: false
      },
      quesOption: {
        params: null,
        items: []
      },
      actButtons: {
        back: {
          icon: 'arrow_left',
          iconSize: '30',
          text: '',
          color: 'transparent',
          foreColor: 'gray800',
          round: 'r0',
          width: 'w88',
          height: 'h88',
          visible: true,
          disabled: false,
          action: this.toBack
        },
        share: {
          openType: 'share',
          icon: 'share',
          iconSize: '36',
          text: '',
          color: 'transparent',
          foreColor: 'gray800',
          round: 'r0',
          width: 'w88',
          height: 'h88',
          visible: true,
          disabled: true,
          action: null
        },
        putin: {
          icon: '',
          iconSize: '30',
          text: '报名',
          color: 'blue',
          foreColor: 'white',
          round: 'r0',
          span: 's2',
          width: 'full',
          height: 'h88',
          visible: true,
          disabled: false,
          action: this.toPutin
        }
      },
    }
  },
  methods: {
    checkUser () {
      const me = this
      const callback = function (res) {
        if (res.status) {
          Object.assign(me.userInfo, res.data)
        }
      }

      user.login.isLogined(callback)
    },
    getImageCode () {
      const me = this
      const executed = function (res) {
        // console.log('report getImageCode, res: %o', res)
        if (res.status) {
          const data = res.data || {}
          const temp = data.value || ''
          const zeroInd = temp.lastIndexOf('0')
          me.captcha.key = temp.slice(zeroInd + 1)
          let imgCode = temp.slice(0, zeroInd)
          imgCode = imgCode.replace(/[\r\n]/g, '')
          me.quesApp.imgCode = imgCode
        } else {
          me.quesApp.imgCode = ''
        }
      }

      api.captcha.getCode({
        scope: this.captcha.scope,
        type: this.captcha.type,
        receiver: this.captcha.receiver,
        executed
      })
    },
    configFlipButton (index = 0) {
      const cnt = this.quesApp.total - 1
      let ind = index
      if (ind < 0) {
        ind = 0
      } else if (ind > cnt) {
        ind = cnt
      }

      this.btnPrev.disabled = ind < 1
      this.btnNext.disabled = !(ind < cnt)

      if (this.submitEnableTypes.indexOf(this.quesApp.type) === -1) {
        if (ind === cnt) {
          this.btnNext.visible = false
          this.btnSubmit.disabled = false
          this.btnSubmit.visible = true
        } else {
          this.btnNext.visible = true
          this.btnSubmit.disabled = true
          this.btnSubmit.visible = false
        }
      }

      this.quesApp.current = ind
      this.currQuestion = this.quesInfo.details[ind] || {}
    },
    onPrev (ev) {
      if (this.btnPrev.disabled) {
        return
      }

      this.configFlipButton(this.quesApp.current - 1)
    },
    onNext (ev) {
      if (this.btnNext.disabled) {
        return
      }

      this.configFlipButton(this.quesApp.current + 1)
    },
    getAnswers () {
      this.captcha.value = this.wdCode.value
      const result = {
        answers: [],
        captcha: this.captcha
      }
      const details = this.quesInfo.details
      for (const i in details) {
        const detail = details[i]
        result.answers.push({
          questionId: detail.questionId,
          answerInfo: detail.result || ''
        })
      }
      return result
    },
    toPutin (ev) {
      if (this.actButtons.putin.disabled) {
        return
      }
      survey.putin(this.quesInfo)
    },
    onActionButton (btnName) {
      const btn = this.actButtons[btnName]
      if (btn.disabled) {
        return
      }

      if (typeof btn.action === 'function') {
        btn.action()
      }
    },
    disableQuestions (disabled = false) {
      const details = this.quesInfo.details
      for (const i in details) {
        const detail = details[i]
        detail.readonly = disabled
      }
    },
    checkQuestion () {
      let errCount = 0
      const details = this.quesInfo.details
      for (const i in details) {
        const detail = details[i]
        const result = api.comm.trim(detail.result || '')
        if (!result) {
          detail.error = detail.type === QuesTypes.COMPLETION ? '请填写对应的内容' : '请选择对应的选项'
          errCount += 1
        } else {
          detail.error = ''
        }
      }
      return errCount
    },
    checkCode () {
      let result = ''
      if (!this.wdCode.value) {
        result = '请填写验证码'
      }

      return result
    },
    updateAnswer ({
      answerId,
      score = 0
    }) {
      const data = {
        score
      }
      survey.answer.update({
        id: answerId,
        data
      })
    },
    submitBalance ({
      userId = '0',
      type = '',
      value = 0,
      status = 'success',
      timestamp = 0
    }) {
      const data = {
        siteId: app.init.siteId,
        channelId: app.init.integralChannelId,
        userId,
        type,
        value,
        status,
        submitTimestamp: timestamp
      }

      const executed = function (ev) {
        console.log('submitBalance ev: %o', ev)
      }

      balance.main.create({
        data,
        executed
      })
    },
    onSubmit (ev) {
      const me = this
      const userId = this.userInfo.userId || ''
      const errCount = this.checkQuestion()
      if (errCount) {
        this.showError(`有${errCount}题目没有完成`)
        return
      }

      // const error = this.checkCode()
      // if (error) {
      //   this.showError(error)
      //   return
      // }

      const lbls = survey.comm.getLabels(this.quesInfo.type)
      const executing = function () {
        me.btnSubmit.disabled = true
        me.btnSubmit.loading = true
      }

      const executed = function (res) {
        console.log('onSubmit res: %o', res)
        me.btnSubmit.disabled = false
        me.btnSubmit.loading = false
        if (res.status) {
          const answerId = res.data.answerId
          me.disableQuestions(true)
          me.btnSubmit.disabled = true

          const submitPoint = parseInt(me.quesInfo.submitPoint) || 0
          let score = parseInt(res.data.score) || 0
          score += submitPoint
          me.updateAnswer({
            answerId,
            score
          })

          if (userId) {
            const timestamp = new Date().getTime()
            me.submitBalance({
              userId,
              type: me.quesInfo.type,
              value: score,
              status: balance.comm.States.SUCCESS,
              timestamp
            })
          }

          if (me.quesInfo.type === survey.comm.Types.EXAM) {
            // uni.showModal({
            //   title: '提交成功',
            //   content: '是否需要查看本次测试结果？',
            //   confirmText: '是',
            //   cancelText: '否',
            //   success: (res) => {
            //     if (res.confirm) {
            //       const params = {
            //         options: {
            //           answerId,
            //           title: me.quesInfo.title
            //         }
            //       }
            //       api.page.redirect('answer', params)
            //     } else {
            //       api.page.back()
            //     }
            //   }
            // })
          } else {
            me.showSuccess('提交成功！')
            // api.comm.showDialog({
            //   content: '提交成功'
            // })
          }
        } else {
          const code = `${res.data.code}`
          let content = '提交失败，请联系技术支持'
          switch (code) {
          case '1102':
            content = `本${lbls.title}尚未发布，不能提交结果`
            break
          case '1101':
            content = `本${lbls.title}已结束，不能提交结果`
            break
          case '500':
            content = '服务器应用接口异常'
            break
          }
          me.showError(content)
        }
      }

      this.quesCountdown.finished = true
      const data = this.getAnswers()
      survey.answer.submit({
        id: this.quesInfo.questionnaireId,
        data,
        executing,
        executed
      })
    },
    onTimeup (ev) {
      const me = this
      this.quesCountdown.finished = true
      const lbls = survey.comm.getLabels(this.quesInfo.type)
      const content = `答题时间已到，现在提交本次${lbls.title}结果`
      const title = '提交结果'
      const callback = function () {
        me.onSubmit(ev)
      }

      api.comm.showDialog({
        title,
        content,
        callback
      })
    },
    setSingle (ev) {
      // console.log('setSingle ev: %o', ev)
      const ques = ev.question
      const detail = this.quesInfo.details[ques.index - 1]
      detail.result = ev.result
      this.$set(detail, 'error', '')
    },
    setMultiple (ev) {
      // console.log('setMultiple ev: %o', ev)
      const ques = ev.question
      const detail = this.quesInfo.details[ques.index - 1]
      detail.result = ev.result
      this.$set(detail, 'error', '')
    },
    setCompletion (ev) {
      // console.log('setCompletion ev: %o', ev)
      const ques = ev.question
      const detail = this.quesInfo.details[ques.index - 1]
      detail.result = ev.result
      this.$set(detail, 'error', '')
    },
    changeQuestion (ev) {
      // console.log('changeQuestion ev: %o', ev)
      const ques = ev.question
      switch (ques.type) {
      case this.quesTypes.SINGLE:
        this.setSingle(ev)
        break
      case this.quesTypes.MULTIPLE:
        this.setMultiple(ev)
        break
      case this.quesTypes.COMPLETION:
        this.setCompletion(ev)
        break
      }
    },
    configParams (question) {
      const cfg = question.config || {}
      this.quesLabels = survey.comm.getLabels(question.type)
      this.quesApp.total = question.details.length
      this.quesCountdown.duration = parseInt(cfg.duration || '10') || 10
      this.configFlipButton(0)

      this.actButtons.putin.text = question.buttonText
      this.actButtons.putin.disabled = question.buttonDisabled
    },
    configQuestionOption ({ id, type, title }) {
      this.quesApp.id = id
      this.quesApp.type = type
      this.quesApp.title = title

      if (this.submitEnableTypes.indexOf(type) !== -1) {
        this.btnSubmit.disabled = false
      } else {
        this.btnSubmit.disabled = true
      }
    },
    loadQuestion () {
      const me = this
      const executing = function () {
        // api.comm.showLoading('正在加载...')
      }
      const executed = function (ev) {
        // console.log('loadQuestion, ev: %o', ev)
        // api.comm.hideLoading()
        me.quesApp.current = 0
        me.quesApp.total = 0

        if (ev.status) {
          me.quesInfo = ev.data
          me.configParams(ev.data)
        }
      }

      survey.main.getDetail({
        id: this.quesApp.id,
        executing,
        executed
      })
    },
  }
}
