<template>
  <section>
    <land-hero-alts
      v-if="banItems.length"
      :items="banItems"
      :assist-color="banApp.assistColor"
      :divisible="banApp.divisible"
      :breadcrumbs="banApp.crumbs"
    />
    <land-section
      id="problem_list"
      space="48"
    >
      <v-container>
        <v-row
          justify="center"
          align="center"
        >
          <v-col
            cols="12"
            md="8"
          >
            <div class="d-flex flex-column">
              <div class="d-flex flex-row justify-center align-center">
                <span class="text-h5">{{ quesInfo.title }}</span>
              </div>
              <template v-if="quesInfo.details">
                <div
                  v-for="(detail, index) in quesInfo.details"
                  :key="index"
                  class="d-flex flex-column mt-4"
                >
                  <land-question-single
                    v-if="detail.type === quesTypes.SINGLE"
                    :question="detail"
                    :error="detail.error"
                    @change="changeQuestion"
                  />
                  <land-question-multiple
                    v-else-if="detail.type === quesTypes.MULTIPLE"
                    :question="detail"
                    :error="detail.error"
                    @change="changeQuestion"
                  />
                </div>
              </template>
              <div class="d-flex flex-column justify-center align-center mt-8">
                <!-- <div
                  class="d-flex flex-row"
                  style="width:400px;"
                >
                  <v-text-field
                    v-model="wdCode.value"
                    :label="wdCode.label"
                    outlined
                    dense
                    required
                    :rules="wdCode.rules"
                  />
                  <img
                    v-if="quesApp.imgCode"
                    class="co-h40 co-w120 co-round pl-2"
                    :src="quesApp.imgCode"
                    @click="getImageCode"
                  >
                </div> -->

                <v-btn
                  rounded
                  dark
                  color="primary"
                  width="400"
                  height="48"
                  :loading="btnSubmit.loading"
                  :disabled="btnSubmit.disabled"
                  @click="onSubmit"
                >
                  {{ btnSubmit.text }}
                </v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </land-section>
    <land-alert :ref="alertName" />
  </section>
</template>

<script>
  import api from '@/api/co.api'
  import lib from '@/api/co.lib'

  import mixAlert from '@/pages/mixins/mix.alert.js'
  import mixHomePsqsBanner from '@/pages/mixins/home/mix.home.psqs.banner'
  import mixSurveyQuestions from '@/pages/mixins/survey/mix.survey.questions'

  export default {
    metaInfo: { title: '问卷调查' },
    mixins: [
      mixAlert,
      mixHomePsqsBanner,
      mixSurveyQuestions
    ],
    data () {
      return {
        opts: {}
      }
    },
    created () {
      const name = api.page.homeProblem.name
      const nav = JSON.parse(lib.cookie.get(name))
      Object.assign(this.quesMeta, nav)

      this.setCrumbs({ title: nav.title })

      this.getImageCode()

      this.initBannerParams({
        topicId: this.banTopicId,
        categoryId: this.banCategoryId
      })
      this.loadBanners()

      this.configQuestionOption({
        id: nav.questionnaireId,
        type: nav.type,
        title: nav.title || ''
      })

      this.loadQuestion()
    }
  }
</script>
