
import api from '@/api/co.api'
import dayjs from 'dayjs'

const coUserSite = {
  getParams ({
    search = '',
    caches = [],
    executing = null,
    executed = null
  }) {
    const me = this
    const obj = {
      url: api.url.MNG_USER_SITE_GETSITES,
      data: {
        search,
        offset: '0',
        limit: '20'
      },
      items: caches || [], // 保存已请求到的数据
      reload: false, // 数据是否重新加载
      loadType: 0,
      total: 0,
      executing, // 请求前执行
      executed, // 请求后执行
      transform: me.convertItems
    }

    return obj
  },
  convertItem (srcItem) {
    const openStart = dayjs(srcItem.openStart || '2020-10-10 10:00:00').format('HH:mm')
    const openEnd = dayjs(srcItem.openEnd || '2020-10-10 22:00:00').format('HH:mm')

    let validityStart = ''
    let validityEnd = ''
    if (srcItem.validityStart) {
      validityStart = dayjs(srcItem.validityStart).format('YYYY-MM-DD HH:mm')
    }

    if (srcItem.validityEnd) {
      validityEnd = dayjs(srcItem.validityEnd).format('YYYY-MM-DD HH:mm')
    }

    const dstItem = {
      ...srcItem,
      openStart,
      openEnd,
      validityStart,
      validityEnd,
      openTime: [openStart, openEnd]
    }
    return dstItem
  },
  convertItems (params, srcItems) {
    const me = this
    srcItems.forEach(item => {
      const dstItem = me.convertItem(item)
      params.items.push(dstItem)
    })
    return params.items.length
  }
}

export default coUserSite
