
import api from '@/api/co.api'
import app from '@/api/co.app'

import cookies from '@/api/co.libs.cookies'

const UserEnjoy = {
  discount: 1.0,
  enable: false,
  text: '',
  userId: '',
  siteId: '',
  siteName: '',
  coupon: '1.0',
  relationId: '',
  type: '',
  userLevel: '',
  userTags: [],
  userTagText: '',
  companyId: '',
  companyName: '',
  department: ''
}

const coUserSignin = {
  // enjoy 客户隶属站点相关信息
  enjoy: UserEnjoy,
  // profile 客户信息
  profile: {},
  token: null,
  login ({
    data,
    executing = null,
    executed = null
  }) {
    api.http.push({
      url: api.url.UC_SSO_LOGIN,
      data,
      executing,
      executed
    })
  },
  saveProfile (profile) {
    if (profile) {
      this.profile = api.comm.deepClone(profile)
    }
  },
  isLogined (callback) {
    let status = false

    const tokenInfos = cookies.get('tokenInfos')
    if (tokenInfos) {
      this.token = JSON.parse(tokenInfos)
    }
    if (!this.token) {
      if (typeof callback === 'function') {
        callback(status)
      }
      return false
    }

    if (this.token.expires < (Date.now() + app.init.expireOffset)) {
      return this.updateToken(this.token, callback)
    } else {
      if (typeof callback === 'function') {
        status = true
        callback(status)
      }
      return true
    }
  },
  getToken () {
    let result = null
    const infos = cookies.get('tokenInfos')
    if (infos) {
      try {
        result = JSON.parse(infos)
      } catch {
        result = null
        console.warn('parse tokenInfos is error, tokenInfos: %o', infos)
      }
    }
    return result
  },
  updateToken (token, callback = null) {
    const me = this
    const trigger = function (status, resp) {
      // console.log('updateToken, status: %o, resp: %o', status, resp)
      if (status) {
        cookies.set('tokenInfos', JSON.stringify(resp.data))
        me.saveToken(resp.data)
        const cookieSetting = {
          expires: parseInt(resp.data.expires)
        }
        cookies.set('token', resp.data.accessToken, cookieSetting)
      } else {
        console.warn('updateToken is error, resp: %o', resp)
      }

      if (typeof callback === 'function') {
        callback(status)
      }
    }
    api.http.get(api.url.UC_SSO_REFESH, { refreshToken: token.refreshToken }, trigger)
    return true
  },
  refreshToken () {
    const token = this.getToken()
    if (token) {
      this.updateToken(token)
    } else {
      console.warn('refreshToken, tokenInfos is null')
    }
  },
  saveToken (data) {
    this.token = api.comm.deepClone(data)
  },
  getProfile (callback = null) {
    const me = this
    const executed = function (status, data) {
      if (typeof callback === 'function') {
        const ev = {
          status,
          data
        }
        callback(ev)
      }
    }

    const trigger = function (status) {
      if (status) {
        const _infoCallback = function (state, resp) {
          console.log('co.user.signin getProfile, status: %o, resp: %o', state, resp)
          if (state && resp) {
            me.saveProfile(resp.data)
          }

          executed(state, me.profile)
        }
        api.http.get(api.url.UC_USER_INFO, null, _infoCallback)
      } else {
        executed(status, me.profile)
      }
    }

    me.isLogined(trigger)
  },
  getRelationBySite (callback = null) {
    const params = {
      siteId: app.init.siteId
    }

    const trigger = function (status, resp) {
      console.log('co.user.signin getRelationBySite, status=%o, resp=%o', status, resp)
      if (status && resp.data) {
        const _enable = parseInt(resp.data.enable) || 0
        if (_enable) {
          UserEnjoy.enable = true
        } else {
          UserEnjoy.enable = false
        }

        const _coupon = parseFloat(resp.data.coupon)
        let _strCoupon = _coupon.toFixed(2)
        if (_strCoupon >= '1.00') {
          UserEnjoy.discount = _coupon
          UserEnjoy.enable = false
        } else {
          UserEnjoy.discount = _coupon
        }

        _strCoupon = (_coupon * 10).toFixed(1)
        if (_strCoupon.endsWith('.0')) {
          _strCoupon = (_coupon * 10).toFixed(0)
        }
        UserEnjoy.text = _strCoupon + '折'
        UserEnjoy.companyId = resp.data.companyId || ''
        UserEnjoy.companyName = resp.data.companyName || ''
        UserEnjoy.department = resp.data.department || ''
        UserEnjoy.coupon = resp.data.coupon
        UserEnjoy.relationId = resp.data.relationId
        UserEnjoy.siteId = resp.data.siteId
        UserEnjoy.siteName = resp.data.siteName
        UserEnjoy.type = resp.data.type
        UserEnjoy.userId = resp.data.userId
        UserEnjoy.userLevel = resp.data.userLevel
        UserEnjoy.userTagText = resp.data.userTags
        UserEnjoy.code = 0
        UserEnjoy.message = ''
      } else {
        UserEnjoy.code = resp.data.code
        UserEnjoy.message = resp.data.message
        UserEnjoy.enable = false
        UserEnjoy.discount = 1.0
        UserEnjoy.text = '(无)'
      }

      if (typeof callback === 'function') {
        callback({
          status,
          data: UserEnjoy
        })
      }
    }
    api.http.get(api.url.UC_SR_RELATION, params, trigger)
  }
}

export default coUserSignin
